<template>
  <Chart :spec="spec" @select="handleSelect" />
</template>

<script lang="ts" setup>
import { generateValueSets, generatorAlias } from "@/reader/lib/visualization";
import { PieChartVisualization } from "@/reader/lib/visualizationTypes";
import { ComputedRef, computed, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { FloatValue, GraphValue, stringifyValue, toNative } from "@/common/lib/value";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { GraphCompoundValue, stringifyValueOrCompositeValue } from "@/common/lib/graph";

// XXX Nearly all of this is copy-pasted from DiscreteDistribution.

const props = defineProps<{
  visualization: PieChartVisualization;
  results: UseQueryResult[];
  width?: number;
  height?: number;
}>();
const { visualization, results, width, height } = toRefs(props);

const emit = defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

interface Datum {
  index: number;
  category: GraphValue | GraphCompoundValue | null;
  categoryId: string;
  categoryName: [string, string];
  value: number;
  tooltip: Record<string, string>;
}

// TODO(jstreufert) this is copy-pasted from DiscreteDistribution
const data = computed(function () {
  const viz = visualization.value;
  const valueSets = generateValueSets(viz.config, results.value, viz.query, ["category", "value"]);
  return valueSets.map(function (values, index): Datum {
    const categoryId = stringifyValueOrCompositeValue(values.category?.originalValue);
    const categoryName = stringifyValue((values.category_name ?? values.category)?.formattedValue);
    return {
      index,
      category: values.category?.originalValue ?? null,
      categoryId,
      categoryName: [categoryName, categoryId],
      value: toNative(values.value!.originalValue as FloatValue),
      tooltip: { [categoryName]: stringifyValue(values.value!.formattedValue) },
    };
    // Why does categoryName include the id as well? Because both the domain and
    // range of the scale mapping categoryId to categoryName must be unique, so
    // without this, duplicate categoryNames cause brokenness
  });
});

const spec: ComputedRef<vega.Spec> = computed(function () {
  const chartWidth = (width.value ?? 370) - 10;
  const chartHeight = (height.value ?? 300) - 10;
  const outerRadius = Math.min(chartWidth - 100, chartHeight) / 2; // 100 is a carve-out for the legend
  const innerRadius = outerRadius * 0.66;
  const spec: vega.Spec = {
    width: chartWidth,
    height: chartHeight,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
        transform: [
          {
            type: "pie",
            field: "value",
          },
        ],
      },
    ],
    signals: [
      {
        name: "selection",
        value: null,
        on: [{ events: "@arc:click", update: "datum.category" }],
      },
    ],
    scales: [
      {
        name: "color",
        type: "ordinal",
        domain: { data: "table", field: "categoryId" },
        range: { scheme: "category20" },
      },
      {
        name: "category_names",
        type: "ordinal",
        domain: { data: "table", field: "categoryId" },
        range: { data: "table", field: "categoryName" },
      },
    ],
    marks: [
      {
        name: "arc",
        type: "arc",
        from: { data: "table" },
        encode: {
          enter: {
            fill: { scale: "color", field: "categoryId" },
            x: { value: outerRadius },
            y: { value: chartHeight / 2 },
            startAngle: { field: "startAngle" },
            endAngle: { field: "endAngle" },
            cursor: { value: "pointer" },
            tooltip: { signal: "datum.tooltip" },
          },
          update: {
            innerRadius: { value: innerRadius },
            outerRadius: { value: outerRadius },
          },
          hover: {
            innerRadius: { value: innerRadius - 5 },
            outerRadius: { value: outerRadius + 5 },
          },
        },
      },
    ],
    legends: [
      {
        fill: "color",
        symbolLimit: 10,
        labelLimit: chartWidth - outerRadius * 2 - 40,
        encode: {
          symbols: {
            name: "legendSymbol",
            enter: {
              strokeWidth: { value: 2 },
              size: { value: 200 },
            },
          },
          labels: {
            name: "legendLabel",
            update: {
              text: { signal: "scale('category_names', datum.value)[0]" },
              fontSize: { value: 12 },
            },
          },
        },
      },
    ],
  };
  return spec;
});

function handleSelect(category: unknown) {
  const alias = generatorAlias(visualization.value.config.category);
  if (alias) emit("select", alias, category as GraphValue | null);
}
</script>
