import useKnowledge from "@/common/composables/useKnowledge";
import { propertyName, propertyValueType } from "@/common/lib/derived";
import { formatValue, noOpFormatValue, ValueWithFormattedValue } from "@/common/lib/format";
import { GraphCompoundValue } from "@/common/lib/graph";
import {
  COMPOSITE_PROPERTY_VALUE_TYPE,
  VALUE_TYPE_BASE_PROPERTY_TYPES,
} from "@/common/lib/knowledge";
import { findDeepColumnByAlias, Query, QueryPathNode } from "@/common/lib/query";
import { GraphValue, GraphValueType, isValue, toValue } from "@/common/lib/value";
import { every, isEqual, isString, last, mapValues } from "lodash";
import { UseQueryResult } from "../composables/useQuery";
import { readerConceptTitle } from "./concept";

// Basic types and utilities for the use of visualizations

export enum ValueGeneratorType {
  Property = "property",
  Title = "title",
}

interface BaseValueGenerator {
  type: ValueGeneratorType;
}

export interface PropertyValueGenerator extends BaseValueGenerator {
  type: ValueGeneratorType.Property;
  alias: string;
  transformer?: string; // Temporary - hold your nose and take a look at format.ts' TRANSFORMERS
}

interface TitleValueGenerator extends BaseValueGenerator {
  type: ValueGeneratorType.Title;
  path?: QueryPathNode[]; // If not specified, root concept is used
}

type NormalizedValueGenerator = PropertyValueGenerator | TitleValueGenerator;

// If you specify a string, it should be an alias (and you get a PropertyValueGenerator)
export type ValueGenerator = NormalizedValueGenerator | string;

export function generateValue(
  generator: ValueGenerator,
  result: UseQueryResult,
  query: Query
): ValueWithFormattedValue | null {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const value = oversimplifyValues(result.valuesByAlias[generator.alias] ?? []);
      if (value == null) return null;
      const propType =
        findDeepColumnByAlias(query, generator.alias)?.property_type ??
        VALUE_TYPE_BASE_PROPERTY_TYPES[value._type];
      return formatValue(propType, value, generator.transformer);
    }
    case ValueGeneratorType.Title: {
      const concept =
        generator.path != null
          ? result.conceptsByPath.find((cbp) => isEqual(cbp.path, generator.path))?.concepts[0]
          : result.root;
      if (concept == null) return null;
      const title = readerConceptTitle(concept);
      if (title == null) return null;
      return noOpFormatValue(toValue(title));
    }
  }
}

export function generateValueSets(
  generators: Record<string, ValueGenerator>,
  results: UseQueryResult[],
  query: Query,
  requiredValues: string[] = []
) {
  // Perhaps warn or report about invalid value sets
  return results
    .map((row) => generateValues(generators, row, query))
    .filter((valueSet) => every(requiredValues, (rv) => valueSet[rv] != null));
}

export function generateValues(
  generators: Record<string, ValueGenerator>,
  result: UseQueryResult,
  query: Query
) {
  return mapValues(generators, (generator) => generateValue(generator, result, query));
}

// Gives the value type that a generator will return
export function generatorOutputType(
  generator: ValueGenerator,
  query: Query
): GraphValueType | null {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const propDef = findDeepColumnByAlias(query, generator.alias)?.property_type;
      if (propDef == null) return null;
      const valueType = propertyValueType(propDef);
      if (valueType === COMPOSITE_PROPERTY_VALUE_TYPE) return null;
      return valueType;
    }
    case ValueGeneratorType.Title:
      return GraphValueType.String;
  }
}

// Tries to come up with a reasonable label for a generator's output
export function generatorName(generator: ValueGenerator, query: Query): string {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const propDef = findDeepColumnByAlias(query, generator.alias)?.property_type;
      if (propDef == null) return "?";
      return propertyName(propDef, undefined); // TODO Probably include concept
    }
    case ValueGeneratorType.Title: {
      const conceptType = generator.path
        ? last(generator.path)!.concept_type
        : query.root_concept_type;
      return useKnowledge().typeLabel(conceptType);
    }
  }
}

function normalizeGenerator(generator: ValueGenerator): NormalizedValueGenerator {
  if (isString(generator)) {
    return { type: ValueGeneratorType.Property, alias: generator };
  } else {
    return generator;
  }
}

export function generatorAlias(generator: ValueGenerator) {
  generator = normalizeGenerator(generator);
  if (generator.type === ValueGeneratorType.Property) {
    return generator.alias;
  } else {
    return null;
  }
}

export function oversimplifyValues(values: (GraphValue | GraphCompoundValue)[]) {
  if (values.length != 1) return null;
  if (!isValue(values[0])) return null; // Conveniently ignoring compound values for now
  return values[0] as GraphValue;
}

// Hardcoded for now, but we know this is going to change at runtime
// These ought to match a color in tailwind.config.js
export function visualizationTheme(darkMode: boolean) {
  return {
    datum: "#F8915B",
    selectedDatum: "#F75E0E",
    label: darkMode ? "#FFFFFF" : "#191919",
    axis: darkMode ? "#FFFFFF" : "#191919",
    brush: "#DDDDDD",
  };
}
